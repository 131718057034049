import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import queryString from "query-string"

import axios from 'axios';

/* API DOCS */

/*

Wallet endpoints on the stage:

https://gic-dev.everymatrix.com/gameAPI/generic/v10/Mima/Authenticate
https://gic-dev.everymatrix.com/gameAPI/generic/v10/Mima/GetBalance
https://gic-dev.everymatrix.com/gameAPI/generic/v10/Mima/Bet
https://gic-dev.everymatrix.com/gameAPI/generic/v10/Mima/Win
https://gic-dev.everymatrix.com/gameAPI/generic/v10/Mima/Cancel


Launch URL:

&language=EN
&freePlay=false
&mobile=true
&mode=dev
&token= tdzdfcXQDkGXZXxC0p8UZgrUpPAaAc~6
&currencyCode=EUR
&lobbyUrl=https%3a%2f%2fgamelaunch-
stage.everymatrix.com%2fLoader%2fLobbyResolver%2f6%2f315%3fcasinolobbyurlencoded%3d03zkwWNTljosCJL4zBe2TJzPjC 37hSVMjO01e9MSgl-VRjge6xOouD5t_udnwGMO%26lobbyurlcase%3d0 

*/

const baseURL = "https://gic-dev.everymatrix.com/gameAPI/generic/v10/Mima";

function TheGame() {

    const location = useLocation()
    const queryParams = queryString.parse(location.search)

    useEffect(() => {
        
        axios.post(baseURL + "/Authenticate", {
            "LaunchToken": queryParams.token,
            "RequestScope": ""
        }).then((response) => {
            console.log(response)
        });

    }, [queryParams.token]);

	return (
		<div className="TheGame">
            <ul>
                <li>Game id: { queryParams && queryParams.gameId }</li>
                <li>Token: { queryParams && queryParams.token }</li>
                <li>freePlay: { queryParams && queryParams.freePlay }</li>
                <li>mobile: { queryParams && queryParams.mobile }</li>
                <li>mode: { queryParams && queryParams.mode }</li>
                <li>currencyCode: { queryParams && queryParams.currencyCode }</li>
                <li>lobbyUrl: { queryParams && queryParams.lobbyUrl }</li>
            </ul>
		</div>
	);
}

export default TheGame;
