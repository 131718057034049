import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import TheGame from './TheGame'

import './App.css';

function App() {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route exact path="/game" element={<TheGame />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
